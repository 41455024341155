import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import icons from './icons.json';

@Component({
  selector: 'app-keenicon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './keenicon.component.html',
  styleUrl: './keenicon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeeniconComponent implements OnInit {
  @Input() name = '';
  @Input() class = '';
  @Input() type = 'duotone';

  pathsNumber = 0;

  ngOnInit() {
    if (this.type === 'duotone') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.pathsNumber = icons[this.type + '-paths'][this.name] ?? 0;
    }
  }

  @HostBinding('style.display')
  get styleDisplay() {
    return 'contents';
  }
}
